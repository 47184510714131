import { useEffect, useState } from 'react';
import getDateString, { formatNumber, formatNumberWithCommas, getMostRecentWeekday } from "../Utils";
import NumberFlow from '@number-flow/react'

const MarketDetailsComponent = ({
    isSearching = false,
    ticker = "SPY",
    date = getDateString(),
    collectionName = `sentiment_tracking${date}`,
    currentCallPutData = { callCount: 0, putCount: 0, callPremium: 0, putPremium: 0 }
}) => {
    // New state to store the currentCallPutData prop locally
    const [localCallPutData, setLocalCallPutData] = useState(currentCallPutData);

    // Keep the local state in sync with the prop
    useEffect(() => {
        setLocalCallPutData(currentCallPutData);
    }, [currentCallPutData]);

    const [p2CRatio, setP2CRatio] = useState(0);
    const [callsPercentage, setCallsPercentage] = useState(0.0);
    const [putsPercentage, setPutsPercentage] = useState(0.0);
    const [tickerPrice, setTickerPrice] = useState(0.0);
    const [tickerChangeFromPrev, setTickerChangeFromPrev] = useState(0.0);
    const [titleText, setTitleText] = useState("Options Flow Data");
    const [isSearching_, setIsSearching_] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(0.0);
    const [bearishSentimentPercent, setBearishSentimentPercent] = useState(0);
    const [bullishSentimentPercent, setBullishSentimentPercent] = useState(0);
    const [bullishOrBearish, setBullishOrBearish] = useState("Neutral");
    const [tickerForSearch, setTickerForSearch] = useState("");
    const [VTPercentChange, setVTPercentChange] = useState(0.0);
    const [articleDetails, setArticleDetails] = useState(null);

    useEffect(() => {
        setTickerForSearch(ticker);
        setIsSearching_(true);
    }, [ticker]);

    useEffect(() => {
        setIsSearching_(isSearching);
    }, [isSearching]);

    useEffect(() => {
        if (bullishSentimentPercent === bearishSentimentPercent) {
            setBullishOrBearish('Neutral');
        } else if (bullishSentimentPercent > bearishSentimentPercent) {
            setBullishOrBearish('Bullish');
        } else {
            setBullishOrBearish('Bearish');
        }
    }, [bullishSentimentPercent, bearishSentimentPercent]);

    // Use the localCallPutData instead of currentCallPutData
    useEffect(() => {
        const { callCount, putCount } = localCallPutData;
        const p2C = callCount === 0 ? Infinity : putCount / callCount;
        setP2CRatio(Number.isFinite(p2C) ? Number(p2C.toFixed(2)) : Infinity);

        const total = callCount + putCount;
        if (total > 0) {
            setCallsPercentage(Number(((callCount / total) * 100).toFixed(1)));
            setPutsPercentage(Number(((putCount / total) * 100).toFixed(1)));
        } else {
            setCallsPercentage(0);
            setPutsPercentage(0);
        }
    }, [localCallPutData]);

    // Optional: If you still need ticker price data
    useEffect(() => {
        let isMounted = true;
        const fetchData = async (isVt = false) => {
            try {
                const mostRecentWeekDay = getMostRecentWeekday();
                let url;
                let searchTicker = tickerForSearch
                if (searchTicker === "") {
                    searchTicker = "SPY";
                }
                if (isVt) {
                    url = `https://us-central1-liteflow-40a37.cloudfunctions.net/stonks?stockTicker=VT&date=${mostRecentWeekDay}`;
                } else {
                    url = `https://us-central1-liteflow-40a37.cloudfunctions.net/stonks?stockTicker=${searchTicker}&date=${mostRecentWeekDay}`;
                }
                const response = await fetch(url);
                if (!response.ok) {
                    console.log("HTTP error getting stock ticker price data");
                }
                const result = await response.json();
                const currentPrice = Number(result.latestPrice).toFixed(2);
                setCurrentPrice(currentPrice);
                const previousClose = result.prevClose;
                const percentageChange = ((currentPrice - previousClose) / previousClose) * 100;
                if (isVt) {
                    setVTPercentChange(
                        isNaN(percentageChange) ? "N/A" : Number(percentageChange).toFixed(2)
                    );
                } else {
                    setTickerPrice(
                        isNaN(result.latestPrice) ? "N/A" : Number(result.latestPrice).toFixed(2)
                    );
                    setTickerChangeFromPrev(
                        isNaN(percentageChange) ? "N/A" : Number(percentageChange).toFixed(2)
                    );
                }
            } catch (err) {
                console.error("Ticker data error 189373673" + err);
            }
        };

        fetchData();
        if (tickerForSearch === "") {
            fetchData(true);
        }

        if (isMounted) {
            fetchArticleDetails();
        }

        return () => {
            isMounted = false;
        };
    }, [tickerForSearch]);

    const fetchArticleDetails = async () => {
        if (tickerForSearch === "") {
            return;
        }
        try {
            const response = await fetch(
                `https://api.bullflow.io/v1/getStockArticle?ticker=${tickerForSearch}`
            );

            if (!response.ok) {
                console.log("HTTP error getting article details");
                return;
            }

            const article = await response.json();
            setArticleDetails(article);
        } catch (err) {
            console.error("Article details error: " + err);
        }
    };

    // Destructure from localCallPutData instead of currentCallPutData
    const { callCount, putCount, callPremium, putPremium } = localCallPutData;

    return (
        <div style={{
            width: '60%',
            height: '100%',
            backgroundColor: '#000000',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'flex-start',
            gap: '12px', // Use gap to control spacing between items
            padding: '8px 12px'
        }}>
            {/* Section 1: weight 3 */}
            <div style={{ flexGrow: 3, flexShrink: 1, flexBasis: 0 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '7px' }}>
                    <p style={{
                        color: titleText === "Options Flow Data" ? '#879097' : "#FFF",
                        fontWeight: 550,
                        fontSize: '0.85em',
                        margin: 0
                    }}>
                        {isSearching_ ? tickerForSearch : "Options Flow Data"}
                    </p>
                    {isSearching_ && articleDetails ? (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '0px',
                            width: '85px',
                            backgroundColor: tickerChangeFromPrev >= 0 ? 'rgba(75, 183, 144, 0.3)' : 'rgba(204, 55, 83, 0.3)',
                            borderRadius: '5px',
                            padding: '3px 7.5px',
                        }}>
                            <p style={{
                                fontWeight: 550,
                                color: tickerChangeFromPrev >= 0 ? '#20d46b' : '#cc3753',
                                fontSize: '0.65em',
                                margin: 0
                            }}>
                                {isSearching_ ? "" : "SPY"} {isSearching ? currentPrice : ""}  {tickerChangeFromPrev}%
                            </p>
                        </div>
                    ) : null}
                </div>
                {isSearching_ && articleDetails ? (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '250px',
                        height: '60px',
                        alignItems: 'flex-start',
                        gap: '0px',
                        backgroundColor: '#36363680',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        padding: '3px 7.5px',
                        marginTop: '5px'
                    }}>
                        <a href={articleDetails.article_url} target="_blank" rel="noopener noreferrer">
                            <p style={{
                                color: '#FFF',
                                fontWeight: 500,
                                fontSize: '0.65em',
                                margin: 0,
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                            }}>
                                {articleDetails.title.length > 120 ? articleDetails.title.slice(0, 120) + '...' : articleDetails.title}
                            </p>
                        </a>
                        <p style={{ color: '#879097', fontSize: '0.7em', margin: 0 }}>
                            {articleDetails.publisher.name}
                        </p>
                    </div>
                ) : (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '0px',
                        width: '85px',
                        backgroundColor: tickerChangeFromPrev >= 0 ? 'rgba(75, 183, 144, 0.3)' : 'rgba(204, 55, 83, 0.3)',
                        borderRadius: '5px',
                        padding: '3px 7.5px',
                    }}>
                        <p style={{
                            fontWeight: 550,
                            color: tickerChangeFromPrev >= 0 ? '#20d46b' : '#cc3753',
                            fontSize: '0.65em',
                            margin: 0
                        }}>
                            {isSearching_ ? "" : "SPY"} {isSearching ? currentPrice : ""}  {tickerChangeFromPrev}%
                        </p>
                    </div>
                )}
                {!isSearching_ && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '85px',
                        alignItems: 'center',
                        gap: '0px',
                        backgroundColor: tickerChangeFromPrev >= 0 ? 'rgba(75, 183, 144, 0.3)' : 'rgba(204, 55, 83, 0.3)',
                        borderRadius: '5px',
                        padding: '3px 7.5px',
                        marginTop: '5px'
                    }}>
                        <p style={{
                            fontWeight: 550,
                            color: tickerChangeFromPrev >= 0 ? '#20d46b' : '#cc3753',
                            fontSize: '0.6em',
                            margin: 0
                        }}>
                            VT {VTPercentChange}%
                        </p>
                    </div>
                )}
            </div>

            {/* Section 2: weight 2 */}
            <div style={{ flexGrow: 2, flexShrink: 1, flexBasis: 0 }}>
                <p style={{ color: '#879097', fontWeight: 550, fontSize: '0.8em', marginBottom: '0px' }}>Put to Call</p>
                <p style={{ fontSize: '12px', fontWeight: 550, fontSize: '0.9em', marginTop: '0px' }}>{isNaN(p2CRatio) ? "∞" : p2CRatio}</p>
                <p style={{ color: '#8f969c', fontSize: '0.7em', marginTop: '2px' }}>
                    {callCount > putCount ? "Bullish" : "Bearish"}
                </p>
                <div style={{
                    height: '7.5px',
                    width: '100%',
                    backgroundColor: '#555',
                    borderRadius: '5px',
                    marginTop: '6px',
                }}>
                    <div style={{
                        height: '100%',
                        width: p2CRatio === Infinity || p2CRatio >= 1 ? '100%' : `${p2CRatio * 100}%`,
                        backgroundColor: callCount > putCount ? '#20d46b' : '#cc3753',
                        borderRadius: '5px',
                    }} />
                </div>
            </div>

            {/* Section 3: weight 2 */}
            <div style={{ flexGrow: 2, flexShrink: 1, flexBasis: 0 }}>
                <p style={{ color: '#879097', fontWeight: 550, fontSize: '0.8em', marginBottom: '0px' }}>Puts</p>
                <p style={{ fontWeight: 550 }}>{formatNumberWithCommas(putCount)}</p>
                <p style={{ color: '#8f969c', fontSize: '0.75em', marginTop: '2px', display: 'block' }}>${formatNumber(putPremium)}</p>

                <div style={{
                    height: '7.5px',
                    width: '100%',
                    backgroundColor: '#555',
                    borderRadius: '5px',
                    marginTop: '6px',
                }}>
                    <div style={{
                        height: '100%',
                        width: `${putsPercentage}%`,
                        backgroundColor: '#cc3753',
                        borderRadius: '5px',
                    }} />
                </div>
            </div>

            {/* Section 4: weight 2 */}
            <div style={{ flexGrow: 2, flexShrink: 1, flexBasis: 0 }}>
                <p style={{ color: '#879097', fontWeight: 550, fontSize: '0.8em', marginBottom: '0px' }}>Calls</p>
                <p style={{ fontWeight: 550 }}>{formatNumberWithCommas(callCount)}</p>
                <p style={{ color: '#8f969c', fontSize: '0.75em', marginTop: '2px', display: 'block' }}>${formatNumber(callPremium)}</p>
                <div style={{
                    height: '7.5px',
                    width: '100%',
                    backgroundColor: '#555',
                    borderRadius: '5px',
                    marginTop: '6px',
                }}>
                    <div style={{
                        height: '100%',
                        width: `${callsPercentage}%`,
                        backgroundColor: '#20d46b',
                        borderRadius: '5px',
                    }} />
                </div>
            </div>
        </div>
    );
};

export default MarketDetailsComponent;
