import { collection, addDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';

/**
 * Adds a new token to the "_flow_tokens" Firestore collection.
 *
 * @return {Promise<string|null>} The generated token or null if an error occurred.
 */
async function addFlowToken() {
  try {
    // Generate a random 6-character token
    const token = Math.random().toString(36).substring(2, 8).toLowerCase();

    // Get the current Unix timestamp
    const timestamp = Math.floor(Date.now() / 1000);

    // Reference to the "_flow_tokens" collection
    const tokensCollection = collection(firestore, "_flow_tokens");

    // Add the new document to Firestore
    const docRef = await addDoc(tokensCollection, {
      token,
      timestamp,
    });

    console.log("Token doc written with ID: ", docRef.id);
    // Return the token itself so we can pass it to the fetch call
    return token;
  } catch (e) {
    console.error("Error adding token doc document: ", e);
    return null;
  }
}

/**
 * Fetches today's flow trades from the Quart API.
 * Calls addFlowToken first, then sends the token as a query parameter.
 *
 * @return {Promise<Array<FlowTrade>>} A promise resolving to the list of trades.
 */
async function getTodaysFlow() {
  try {
    // First, generate and store a token
    const token = await addFlowToken();

    // If for some reason token is null, handle it here if desired
    if (!token) {
      console.log("Failed to generate token. Proceeding without token...");
    }

    // Construct the URL with the token as a parameter
    const url = `https://api.bullflow.io/v1/getTodaysFlow?m=f&token=${token || ""}`;

    const response = await fetch(url);
    const responseBody = await response.text();

    if (response.ok) {
      // Check if the response body starts with '[' indicating a direct list
      if (responseBody.trim().startsWith("[")) {
        const flowTrades = JSON.parse(responseBody);
        return flowTrades;
      } else {
        // Attempt to parse as a success response
        const successResponse = JSON.parse(responseBody);

        if (successResponse.status && successResponse.status.toLowerCase() === "success") {
          console.log("Successfully fetched today's flow");
          return successResponse.data || [];
        } else {
          // Handle unexpected status
          console.log("Unexpected status:", successResponse.status);
          return [];
        }
      }
    } else {
      // Attempt to parse as error response
      const errorResponse = JSON.parse(responseBody);
      console.log("API Error:", errorResponse.message || "Unknown error");
      return [];
    }
  } catch (e) {
    // Handle exceptions such as network errors, parsing issues, etc.
    console.log("Error fetching today's flow:", e.message);
    return [];
  }
}

export { getTodaysFlow, addFlowToken };
