import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import tiktokIcon from './tiktok_gray.png';
import youtubeIcon from './youtube_gray.png';
import xLogoIcon from './x_logo_gray.png';
import linkedinIcon from './linkedin_gray.png';
import bull from './bullflow_3.png';
import { firestore } from './firebase';

const Footer = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [subscribed, setSubscribed] = useState(false);

    const handleSubscribe = async () => {
        if (!email) return alert("Please enter a valid email address.");

        try {
            await addDoc(collection(firestore, '_newsletter_emails'), { email });
            setSubscribed(true);
        } catch (error) {
            console.error("Error adding email to Firestore:", error);
            alert("There was an error saving your subscription. Please try again.");
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#000',
                color: '#fff',
                padding: '40px 20px',
                width: '100%',
                marginTop: '50px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    maxWidth: '1200px',
                    flexWrap: 'wrap', // Wrap items if screen size is small
                }}
            >
                {/* Left Section - Branding and Disclaimer */}
                <div style={{ flex: 1, minWidth: '200px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={bull} alt="Logo" style={{ marginRight: '14px', height: '36px', cursor: 'pointer' }} />
                        <p style={{ color: '#fff', fontWeight: 'bold', fontSize: '24px' }}>BullFlow.io</p>
                    </div>
                    <p style={{ color: '#757575', fontSize: '14px', marginTop: '10px' }}>
                        All analysis information is provided for reference purposes only and does not constitute an investment recommendation.
                    </p>
                    <div style={{ marginTop: '20px' }}>
                        <p style={{ color: '#757575', fontSize: '14px' }}>Subscribe to our newsletter</p>
                        {subscribed ? (
                            <p style={{ color: '#fff', fontSize: '14px' }}>Thank you for subscribing!</p>
                        ) : (
                            <div style={{ display: 'flex', marginTop: '4px' }}>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter email"
                                    style={{
                                        padding: '10px',
                                        borderRadius: '4px',
                                        border: 'none',
                                        marginRight: '10px',
                                        flex: 1,
                                    }}
                                />
                                <button
                                    onClick={handleSubscribe}
                                    style={{
                                        padding: '10px 20px',
                                        backgroundColor: '#757575',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }}
                                >
                                    Subscribe
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                {/* Center Section - Product and Legal Links */}
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between',
                        marginLeft: '50px',
                        minWidth: '400px',
                    }}
                    className="footer-center-section"
                >
                    <div style={{ flex: 1 }}>
                        <h4 style={{ color: '#fff', marginBottom: '10px' }}>Product</h4>
                        <ul style={{ listStyle: 'none', padding: 0, color: '#757575', fontSize: '14px' }}>
                            <li
                                style={{ cursor: 'pointer', marginBottom: '8px' }} // Added marginBottom
                                // open in same tab
                                onClick={() => window.open('https://bullflow.io/pricing' )}
                            >
                                Pricing
                            </li>
                            <li
                                style={{ cursor: 'pointer', marginBottom: '8px' }} // Added marginBottom
                                onClick={() => window.open('https://blog.bullflow.io', '_blank')}
                            >
                                Blog
                            </li>
                            <li
                                style={{ cursor: 'pointer', marginBottom: '8px' }} // Added marginBottom
                                onClick={() => window.open('https://bullflow.tolt.io', '_blank')}
                            >
                                Affiliates
                            </li>
                            <li
                                style={{ cursor: 'pointer', marginBottom: '8px' }} // Added marginBottom
                                onClick={() => window.open('https://discord.gg/mZ6JwbsRH7', '_blank')}
                            >
                                Discord
                            </li>
                        </ul>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h4 style={{ color: '#fff', marginBottom: '10px' }}>Other</h4>
                        <ul style={{ listStyle: 'none', padding: 0, color: '#757575', fontSize: '14px' }}>
                            <li
                                style={{ cursor: 'pointer', marginBottom: '8px' }} // Added marginBottom
                                onClick={() => window.open('https://bullflow.io/TermsOfService', '_blank')}
                            >
                                Terms of Service
                            </li>
                            <li
                                style={{ cursor: 'pointer', marginBottom: '8px' }} // Added marginBottom
                                onClick={() => window.open('https://bullflow.io/PrivacyPolicy.html', '_blank')}
                            >
                                Privacy Policy
                            </li>

                            <li
                                style={{ cursor: 'pointer', marginBottom: '8px' }} // Added marginBottom
                                onClick={() => window.open('https://bullflow.io/contact', '_blank')}
                            >
                                Contact
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Right Section - Social Media Icons */}
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', minWidth: '150px' }}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <img
                            src={xLogoIcon}
                            alt="X Logo"
                            style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                            onClick={() => window.open('https://x.com/intent/follow?screen_name=bullflowio', '_blank')}
                        />
                        <img
                            src={youtubeIcon}
                            alt="YouTube"
                            style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                            onClick={() => window.open('https://www.youtube.com/@Bullflow', '_blank')}
                        />
                        <img
                            src={tiktokIcon}
                            alt="TikTok"
                            style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                            onClick={() => window.open('https://www.tiktok.com/@bullflow.io', '_blank')}
                        />
                        <img
                            src={linkedinIcon}
                            alt="LinkedIn"
                            style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                            onClick={() => window.open('https://www.linkedin.com/company/bullflow-io', '_blank')}
                        />
                    </div>
                </div>
            </div>

            {/* Footer Bottom Section */}
            <div style={{ marginTop: '30px', color: '#757575', fontSize: '14px' }}>
                © 2024 BullFlow.io. All Rights Reserved.
            </div>

            {/* Inline CSS for Media Queries */}
            <style>
                {`
                    @media (max-width: 600px) {
                        .footer-center-section {
                            margin-top: 20px;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default Footer;
