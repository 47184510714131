import React, { useState, useEffect } from 'react';
import { Slider, Input } from 'antd';
import './bullflowvolumeslider.css';

// Global constant for maximum volume
const MAX_VOLUME = 100000;

function CurrentVolumeFilter({ minCurrentVolumeProp, maxCurrentVolumeProp, handleFilterChange }) {
  const [minCurrentVolume, setMinCurrentVolume] = useState(minCurrentVolumeProp);
  const [maxCurrentVolume, setMaxCurrentVolume] = useState(maxCurrentVolumeProp);

  useEffect(() => {
    setMinCurrentVolume(minCurrentVolumeProp);
  }, [minCurrentVolumeProp]);

  useEffect(() => {
    setMaxCurrentVolume(maxCurrentVolumeProp);
  }, [maxCurrentVolumeProp]);

  // Handle the slider change for both min and max
  const onChangeSlider = (values) => {
    let [newMin, newMax] = values;

    // Clamp values if needed
    if (newMin < 0) newMin = 0;
    if (newMax > MAX_VOLUME) newMax = MAX_VOLUME;

    setMinCurrentVolume(newMin);
    setMaxCurrentVolume(newMax);

    // Pass both values back up to parent
    handleFilterChange({ min: newMin, max: newMax });
  };

  // Handle changes to the min input field
  const onChangeMinInput = (e) => {
    const newMinValue = Number(e.target.value);

    // Validate input
    if (newMinValue >= 0 && newMinValue <= MAX_VOLUME) {
      setMinCurrentVolume(newMinValue);

      // Ensure max is still >= min
      const adjustedMax = Math.max(maxCurrentVolume, newMinValue);
      setMaxCurrentVolume(adjustedMax);

      // Pass both values to parent
      handleFilterChange({ min: newMinValue, max: adjustedMax });
    }
  };

  // Handle changes to the max input field
  const onChangeMaxInput = (e) => {
    const newMaxValue = Number(e.target.value);

    // Validate input
    if (newMaxValue >= 0 && newMaxValue <= MAX_VOLUME) {
      setMaxCurrentVolume(newMaxValue);

      // Ensure min is still <= max
      const adjustedMin = Math.min(minCurrentVolume, newMaxValue);
      setMinCurrentVolume(adjustedMin);

      // Pass both values to parent
      handleFilterChange({ min: adjustedMin, max: newMaxValue });
    }
  };

  return (
    <div style={{ width: '90%', marginTop: '0px' }}>
      <p style={{ fontWeight: 600, fontSize: '0.8em', marginBottom: '10px' }}>Current Volume</p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          marginBottom: '0px'
        }}
      >
        {/* Now the Slider is a range slider */}
        <Slider
          range
          className='bfvolume-gradient-slider'
          min={0}
          max={MAX_VOLUME}
          onChange={onChangeSlider}
          value={[minCurrentVolume, maxCurrentVolume]}
          style={{ flexGrow: 7.5, marginRight: '10px' }}
          tooltip={{
            formatter: (val) => `${val}`,
            visible: true,
            zIndex: 15001
          }}
        />
      </div>
    </div>
  );
}

export default CurrentVolumeFilter;
